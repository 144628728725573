import { Injectable } from '@angular/core';
import { IBillingPeriodService } from '../../../core/interfaces/services/billingPeriod';
import { IActiveBillingPeriods, IBillingPeriod } from '../../../core/interfaces/ports/outputs/BillingPeriod';   
import { Apollo } from 'apollo-angular';
import { BehaviorSubject, Observable, firstValueFrom, map } from 'rxjs';
import { IQueryGetBillingPeriod, query_get_billing_period, mutation_update_billing_period, query_get_billing_period_by_date, IQueryGetBillingPeriodByDate } from '../graphql/BillingPeriod';
import { EBillingPeriodState } from '@core-ports/outputs/BillingPeriodState';
import { Router } from '@angular/router';
import { EBillinPeriodStateOptions } from '@core-ports/inputs/BillingPeriod';


@Injectable({
    providedIn: 'root',
})
export class BillingPeriodManager implements IBillingPeriodService {

    activeBillingPeriodsSubject: BehaviorSubject<IActiveBillingPeriods> = new BehaviorSubject<IActiveBillingPeriods>({});
    activeBillingPeriods = this.activeBillingPeriodsSubject.asObservable();

    constructor(
        private apollo: Apollo,
        private router: Router
    ) {}

    getCurrentBillingPeriod(): Date {
        return new Date(this.activeBillingPeriodsSubject.value.current_period?.start_date!);
    }

    getCollectionBillingPeriod(): Date | null {
        const startDate = this.activeBillingPeriodsSubject.value.collecting_period?.start_date;
        return startDate ? new Date(startDate) : null;
    }


    async getAndUpdateActivePeriods(): Promise<void> {
        const currentPeriod = await firstValueFrom(await this.getBillingPeriod(EBillingPeriodState.CURRENT));
        if (!currentPeriod) {
            throw new Error('No se encontró un periodo activo');
        }
        const collectionPeriod = await firstValueFrom(await this.getBillingPeriod(EBillingPeriodState.COLLECTION));
        const activePeriods: IActiveBillingPeriods = {
            current_period: currentPeriod,
            collecting_period: collectionPeriod
        };
        this.activeBillingPeriodsSubject.next(activePeriods);
    }

    async getBillingPeriod(
        state: EBillingPeriodState
    ): Promise<Observable<IBillingPeriod>> {
        return this.apollo
         .query<IQueryGetBillingPeriod>({
                query: query_get_billing_period,
                fetchPolicy: 'network-only',
                variables: {
                    filter: {
                        state: {
                            eq: state
                        }
                    }
                }
            })
            .pipe(
                map((response) => {
                    if (response.data) {
                        return response.data.GetBillingPeriod;
                    } else {
                        throw new Error('Error al obtener los periodos activos');
                    }
                })
            );
    }

    async getBillingPeriodByDate(date: Date): Promise<Observable<IBillingPeriod | null>> {
        return this.apollo
        .query<IQueryGetBillingPeriodByDate>({
               query: query_get_billing_period_by_date,
               fetchPolicy: 'network-only',
               variables: {
                   period_date: date
               }
           })
           .pipe(
               map((response) => {
                   if (response.data) {
                       return response.data.GetBillingPeriodByDate;
                   } else {
                       throw new Error('Error al obtener los periodos activos');
                   }
               })
           );
    }

    async updateExpirationDate(id: number, expirationDate: string): Promise<void> {
        await this.apollo.mutate({
          mutation: mutation_update_billing_period,
          variables: {
            id: Number(id),
            input: {
              expiration_date: expirationDate
            }
          }
        }).toPromise();
      }
    
      async updateFacturationDate(id: number, facturationDate: string): Promise<void> {
        await this.apollo.mutate({
          mutation: mutation_update_billing_period,
          variables: {
            id: Number(id),
            input: {
              facturation_date: facturationDate
            }
          }
        }).toPromise();
      }
    formatPeriodToMonthYear(date: Date): string {
        const formatedDate = date.toLocaleDateString('es-ES', { month: 'long', year: 'numeric' });
        // Capitalize first letter
        return formatedDate.charAt(0).toUpperCase() + formatedDate.slice(1);
    }

    comparePeriods(period: Date, type: EBillinPeriodStateOptions): boolean {
        const periodToCompare = type === EBillinPeriodStateOptions.CURRENT ? this.activeBillingPeriodsSubject.value.current_period?.start_date : this.activeBillingPeriodsSubject.value.collecting_period?.start_date;
        const currentPeriodDate = new Date(periodToCompare!);
        return (currentPeriodDate.getMonth() === period.getMonth()) && (currentPeriodDate.getFullYear() === period.getFullYear());  
    }

    


}